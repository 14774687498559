.header {
    width: 100%;
    background-color: #48D597;
    height: 780px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.title {
    font-size: 60px;
    text-align: center;
}

.subtitle {
    font-size: 60px;
    color: white;
    margin-bottom: 91px;
    text-align: center;
}

.end-text {
    font-size: 32px;
    text-align: center;
}

.button-end {
    position: absolute;
    bottom: -30px;
    padding: 16px 70px;
    background-color: #000000;
    color: white;
    border-radius: 12px;
    font-size: 24px;
}

.arrowLeft {
    position: absolute;
    left: 15%;
    top: 25%;
}

.arrowRight {
    position: absolute;
    right: 15%;
    bottom: 25%;
}

@media only screen and (max-width: 1440px) {
    .arrowLeft {
        position: absolute;
        left: 7%;
        top: 25%;
    }
    .arrowRight {
        position: absolute;
        right: 7%;
        bottom: 25%;
    }
}

@media only screen and (max-width: 1024px) {
    .arrowLeft {
        position: absolute;
        left: 1%;
        top: 15%;
        z-index: 0;
    }
    .arrowRight {
        position: absolute;
        right: 1%;
        bottom: 10%;
        z-index: 0;
    }
    .header {
        padding-top: 2rem;
    }
}

@media only screen and (max-width: 770px) {
    .header {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
}

@media only screen and (max-width: 665px) {
    .header {
        padding-top: 12rem;
        padding-bottom: 8rem;
    }
    .subtitle {
        font-size: 40px;
        color: white;
        margin-bottom: 91px;
        text-align: center;
        width: 100%;
    }
}

@media only screen and (max-width: 340px) {
    .header {
        padding-top: 16rem;
        padding-bottom: 14rem;
    }
}