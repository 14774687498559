.container {
    padding-top: 190px;
    flex-direction: column;
    display: flex;
    align-items: center;
}

.title {
    text-align: center;
}

.team {
    width: 70%;
    height: 100px;
    border-radius: 20px;
    background-color: #48D597;
    display: flex;
    flex-direction: row;
}

.team-content {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-container {
    width: 70%;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}

.team-container-content {
    width: calc(25% - 0.5rem);
    background-color: #F6F6F6;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.liContainer {
    display: flex;
    flex-direction: row;
    margin-left: 1.5rem;
    align-items: center;
    column-gap: 1rem;
    margin-bottom: 12px;
}

.teamli {
    font-size: medium;
    text-align: left;
}

.button-end {
    padding: 16px 70px;
    background-color: #000000;
    color: white;
    border-radius: 12px;
    font-size: 24px;
}

.cards {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 2rem;
    row-gap: 2rem;
}

.card {
    display: flex;
    flex-direction: column;
    width: calc(30% - 2rem);
    border-radius: 20px;
    padding: 24px;
    border: 1px solid #48D597;
}

.card p {
    font-size: 16px;
}

.card-first {
    display: flex;
    flex-direction: column;
    width: calc(30% - 2rem);
    background-color: #48D597;
    border-radius: 20px;
    padding: 24px;
}

.ilustration {
    width: 120px;
    height: 120px;
    margin-left: calc(50% - 60px);
}

@media only screen and (max-width: 1440px) {
    .team {
        width: 90%;
    }
    .team-container {
        width: 90%;
    }
}

@media only screen and (max-width: 1378px) {
    .team {
        width: 88%;
    }
    .team-container {
        width: 88%;
    }
}

@media only screen and (max-width: 1250px) {
    .team-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 1rem;
        justify-content: center;
    }
    .card {
        width: 44%;
    }
    .card-first {
        width: 44%;
    }
    .team-container-content {
        width: 32%;
    }
}

@media only screen and (max-width: 1150px) {
    .card {
        width: 43%;
    }
    .card-first {
        width: 43%;
    }
}

@media only screen and (max-width: 995px) {
    .card {
        width: 95%;
    }
    .card-first {
        width: 95%;
    }
}

@media only screen and (max-width: 770px) {
    .team-container-content {
        width: 48%;
    }
}

@media only screen and (max-width: 665px) {
    .team-container-content {
        width: 100%;
    }
}

@media only screen and (max-width: 325px) {
    .card {
        width: 83%;
    }
}