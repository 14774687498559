.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 138px;
    background-color: #F6F6F6;
}

.content {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: space-between;
    align-items: center;
}

.image {
    height: 50px;
}

.blackContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    background-color: black;
    color: white;
}

@media only screen and (max-width: 1440px) {
    .content {
        width: 80%;
    }
}

@media only screen and (max-width: 1420px) {
    .content {
        width: 98%;
    }
}

@media only screen and (max-width: 1151px) {
    .content {
        width: 98%;
    }
    .image {
        height: 40px;
    }
}

@media only screen and (max-width: 1025px) {
    .content {
        width: 99%;
    }
    .image {
        height: 35px;
    }
}

@media only screen and (max-width: 981px) {
    .footer {
        height: 150px;
    }
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        column-gap: 1rem;
    }
    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 1rem;
    }
}

@media only screen and (max-width: 650px) {
    .footer {
        height: 211px;
    }
    .center {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    }
    .line {
        display: none;
    }
}