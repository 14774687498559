.container {
    display: flex;
    justify-content: center;
}

.content {
    display: flex;
    flex-direction: row;
    width: 65%;
    column-gap: 2rem;
}

.firstContent {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.secondContent {
    display: flex;
    flex-direction: column;
    width: 50%;
    row-gap: 24px;
}

.inputContent {
    height: calc(40px - 16px);
    padding: 11px 16px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid black;
}

.formContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
}

.areaContent {
    font-family: 'Public Sans';
    height: calc(40px - 16px);
    padding: 11px 16px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid black;
    resize: none;
    height: 100px;
}

.radius {
    width: 20px;
    height: 20px;
}

.button-end {
    width: 70px;
    padding: 16px 70px;
    background-color: #000000;
    color: white;
    border-radius: 12px;
    font-size: 24px;
}

.imgContent {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
}

@media only screen and (max-width: 1322px) {
    .content {
        width: 95%;
    }
}

@media only screen and (max-width: 665px) {
    .content {
        flex-direction: column;
        row-gap: 2rem;
    }
    .firstContent {
        width: 100%;
    }
    .secondContent {
        width: 100%;
    }
}