.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 960px;
    background-color: #E9FAF2;
}

.content {
    display: flex;
    flex-direction: row;
    width: 70%;
    column-gap: 3rem;
}

.firstContent {
    display: flex;
    flex-direction: column;
    width: 65%;
    padding: 150px 90px;
    background-color: white;
    border-radius: 20px;
}

.secondContent {
    display: flex;
    flex-direction: column;
    width: 35%;
    background-color: #48D597;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    position: relative;
}

.secondText {
    color: white;
    text-align: center;
    font-weight: 100;
}

.arrowsRight {
    position: absolute;
    bottom: 20%;
    right: 1rem;
}

.arrowsLeft {
    position: absolute;
    top: 20%;
    left: 1rem;
}


@media only screen and (max-width: 1322px) {
    .content {
        width: 95%;
    }
}

@media only screen and (max-width: 1322px) {
    .content {
        flex-direction: column;
        row-gap: 1rem;
    }
    .firstContent {
        width: calc(100% - 180px);
    }
    .secondContent {
        width: calc(100% - 2rem);
        padding: 1rem;
    }
}

@media only screen and (max-width: 500px) {
    .firstContent {
        padding: 64px 39px;
        width: calc(100% - 83px);
    }
}