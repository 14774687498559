.navbar {
    width: calc(100% - 20%);
    height: 100px;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 16px #00000029;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    z-index: 2;
    position: fixed;
}

.brand {
    height: 48.1px;
}

.menu {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
    cursor: pointer;
}

.menu .link {
    font-size: 18px;
}

.menu .last {
    background-color: #000000;
    padding: 9.5px 20px;
    color: white;
    border-radius: 12px;
}

@media only screen and (max-width: 850px) {
    .navbar {
        width: calc(100% - 10%);
        padding: 0 5%;
    }
}

@media only screen and (max-width: 850px) {
    .brand {
        height: 36.1px;
    }
}

@media only screen and (max-width: 665px) {
    .menu {
        display: none;
    }
}